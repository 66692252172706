<template>
  <div class="wrapper pa-2 d-flex justify-center align-center">
    <v-chip color="primary" text-color="white">
      <v-avatar left>
        <v-icon>schedule</v-icon>
      </v-avatar>
      <span class="pr-2" v-text="$t('streaming.willBeginIn')" />
      <counter-down v-if="date" :date="date" />
    </v-chip>
  </div>
</template>

<script>
export default {
  name: 'StreamingNotStartedAudience',
  props: {
    date: {
      type: Date,
      default: null,
    },
  },
}
</script>
<style lang="scss">
.wrapper {
  height: 100%;
}
</style>
